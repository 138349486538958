import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Spinner from './common/2.0/Spinner';

const EmbeddedMap = React.lazy(() => import('./map/EmbeddedMap'));
const Layout = React.lazy(() => import('./Layout'));
const FetchItemWrapper = React.lazy(() => import('./FetchItemWrapper'));
const ReportsSection = React.lazy(() => import('./reports/ReportsSection'));
const MapSection = React.lazy(() => import('./map/MapSection'));
const ImportView = React.lazy(() => import('./import/ImportView'));
const ScriptsSection = React.lazy(() => import('./scripts/ScriptsSection'));
const NewSettingsSection = React.lazy(() => import('./settings/Settings'));
const Dashboard = React.lazy(() => import('./dashboard'));
const ErrorView = React.lazy(() => import('./ErrorView'));

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="public">
          <Route path="embed">
            <Route path="map">
              <Route path=":nrd" element={<EmbeddedMap />} />
            </Route>
          </Route>
        </Route>
        <Route path="/" element={<Layout />}>
          {[
            'certs',
            'wells',
            'entities',
            'daus',
            'paus',
            'flowmeters',
            'chemigations',
            'retirements',
            'soils',
          ].map((type) => (
            <Route path={type} key={type}>
              <Route path=":id" element={<FetchItemWrapper type={type} />} />
            </Route>
          ))}
          {/* <Route path="settings" element={<SettingsSection />} /> */}
          <Route path="settings" element={<NewSettingsSection />} />
          <Route path="reports" element={<ReportsSection />} />
          <Route path="map" element={<MapSection />} />
          <Route path="import" element={<ImportView />} />
          {/* <Route path="admin" element={<AdminSection />} /> */}
          <Route path="scripts" element={<ScriptsSection />} />
          <Route path="error" element={<ErrorView />} />
          <Route index element={<Dashboard />} />
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
